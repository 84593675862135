import React, { Component } from 'react';
export default class Portfolio extends Component {
  render() {
    return (
      <React.Fragment>
      {/*generated code*/}
      <section id="portfolio">
        <div className="row">
          <div className="twelve columns collapsed">
            <h1>Check Out Some of My Works.</h1>
            {/* portfolio-wrapper */}
            <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
              <div className="columns portfolio-item">
                <div className="item-wrap">
                  <a href="#modal-02">
                    <img alt src="images/portfolio/SchmissileDefenderSplash.jpg" />
                    <div className="overlay">
                      <div className="portfolio-item-meta">
                        <h5>Schmissile Defender</h5>
                        <p>Unity Web Game - Solo</p>
                      </div>
                    </div>
                    <div className="link-icon"><i className="icon-plus" /></div>
                  </a>
                </div>
              </div> {/* item end */}
              <div className="columns portfolio-item">
                <div className="item-wrap">
                  <a href="#modal-03">
                    <img alt src="images/portfolio/SmashyArmsSplash.jpg" />
                    <div className="overlay">
                      <div className="portfolio-item-meta">
                        <h5>Smashy Arms</h5>
                        <p>Unity Web Game - Team</p>
                      </div>
                    </div>
                    <div className="link-icon"><i className="icon-plus" /></div>
                  </a>
                </div>
              </div> {/* item end */}
              <div className="columns portfolio-item">
                <div className="item-wrap">
                  <a href="#modal-04">
                    <img alt src="images/portfolio/AirTrafficSplash.jpg" />
                    <div className="overlay">
                      <div className="portfolio-item-meta">
                        <h5>Air Traffic Controller</h5>
                        <p>Unity Web Game - Team</p>
                      </div>
                    </div>
                    <div className="link-icon"><i className="icon-plus" /></div>
                  </a>
                </div>
              </div> {/* item end */}
              <div className="columns portfolio-item">
                <div className="item-wrap">
                  <a href="#modal-05">
                    <img alt src="images/portfolio/CoinCollectorSplash.jpg" />
                    <div className="overlay">
                      <div className="portfolio-item-meta">
                        <h5>Coin Collector</h5>
                        <p>Unity Web Game - Team</p>
                      </div>
                    </div>
                    <div className="link-icon"><i className="icon-plus" /></div>
                  </a>
                </div>
              </div> {/* item end */}
            </div> {/* portfolio-wrapper end */}
          </div> {/* twelve columns end */}
          {/* Modal Popup
	      --------------------------------------------------------------- */}
          <div id="modal-02" className="popup-modal mfp-hide">
            {/* <img className="scale-with-grid" src="images/portfolio/modals/m-console.jpg" alt /> */}
              {/* <div id="gameContainer" style={{width: '960px', height: '600px'}} /> */}
            <div className="link-box">
              <a href="https://benviss.github.io/SchmissileDefender/">Play Game!&nbsp;&nbsp;</a>
              <a href="https://github.com/benviss/SchmissileDefender">&nbsp;&nbsp;See Code</a>
              <a className="popup-modal-dismiss">Close</a>
            </div>
          </div>{/* modal-02 End */}
          <div id="modal-03" className="popup-modal mfp-hide">
            {/* <img className="scale-with-grid" src="images/portfolio/modals/m-judah.jpg" alt /> */}
            <div className="link-box">
              <a href="https://gamesnstuffdev.itch.io/smashystuff">Play Game! </a>
              <a href="https://github.com/benviss/Ludum41">&nbsp;&nbsp;See Code</a>
              <a className="popup-modal-dismiss">Close</a>
            </div>
          </div>{/* modal-03 End */}
          <div id="modal-04" className="popup-modal mfp-hide">
            {/* <img className="scale-with-grid" src="images/portfolio/modals/m-intothelight.jpg" alt /> */}
            <div className="link-box">
              <a href="https://www.kongregate.com/games/fostermatt82/days-since-last-incident">Play Game!&nbsp;&nbsp;</a>
              <a href="https://github.com/benviss/AirTrafficController">&nbsp;&nbsp;See Code</a>
              <a className="popup-modal-dismiss">Close</a>
            </div>
          </div>{/* modal-04 End */}
          <div id="modal-05" className="popup-modal mfp-hide">
            {/* <img className="scale-with-grid" src="images/portfolio/modals/m-intothelight.jpg" alt /> */}
            <div className="link-box">
              <a href="https://www.kongregate.com/games/fostermatt82/coin-collector">Play Game!&nbsp;&nbsp;</a>
              <a href="https://github.com/benviss/CoinCollector">&nbsp;&nbsp;See Code</a>
              <a className="popup-modal-dismiss">Close</a>
            </div>
          </div>{/* modal-05 End */}
        </div> {/* row End */}
      </section>
      </React.Fragment>
    );
  }
}