import React, { Component } from 'react';
export default class Resume extends Component {
  render() {
    return (
      <React.Fragment>
      {/*generated code*/}
      <section id="resume">
        {/* Education
      ----------------------------------------------- */}
        <div className="row education">
          <div className="three columns header-col">
            <h1><span>Education</span></h1>
          </div>
          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">
                <h3>Washington State University</h3>
                <p className="info">B.A. Accounting<span>•</span> <em className="date">December 2014</em></p>
              </div>
            </div> {/* item end */}
            <div className="row item">
              <div className="twelve columns">
                <h3>Epicodus</h3>
                <p className="info">Vocational Software Development Program<span>•</span> <em className="date">September 2016</em></p>
              </div>
            </div> {/* item end */}
          </div> {/* main-col end */}
        </div> {/* End Education */}
        {/* Work
      ----------------------------------------------- */}
        <div className="row work">
          <div className="three columns header-col">
            <h1><span>Work</span></h1>
          </div>
          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">
                <h3>Software Developer</h3>
                <p className="info">Traffic Technology Services<span>•</span> <em className="date">November 2018 - Present</em></p>
                <p>
                    <ul>
                      <li>Personally managed development and deployment of software for Canada.</li>
                      <li>Design, implement, and maintain software for in-house as well as external customers.</li>
                      <li>Collaborate with our software team to come up with optimized and robust solutions.</li>
                      <li>Collaborate with our engineers and sales team to continually refine usability and functionality.</li>
                    </ul>  
                </p>
              </div>
            </div> {/* item end */}
            <div className="row item">
              <div className="twelve columns">
                <h3>Junior Software Developer</h3>
                <p className="info">Traffic Technology Services<span>•</span> <em className="date">December 2017 - November 2018</em></p>
                  <p>
                    <ul>
                      <li>Assist in implementation of new software for in-house as well as external customers.</li>
                      <li>Redesign existing features to improve efficiency.</li>
                      <li>Actively supported software deployment by identifying software bugs and implementing solutions.</li>
                    </ul>
                  </p>
              </div>
            </div> {/* item end */}
            <div className="row item">
              <div className="twelve columns">
                <h3>Software Developer Internship</h3>
                <p className="info">Traffic Technology Services<span>•</span> <em className="date">May 2017 - December 2017</em></p>
                  <p>
                    <ul>
                      <li>Did in depth testing on new services of core software.</li>
                      <li>Actively supported software deployment by identifying software bugs and implementing solutions.</li>
                      <li>Assisted in releasing production signals in other cities.</li>
                      <li>Collaborate with our engineers and sales team to continually refine usability and functionality.</li>
                    </ul>
                  </p>
              </div>
            </div> {/* item end */}
          </div> {/* main-col end */}
        </div> {/* End Work */}
        {/* Skills
      ----------------------------------------------- */}
        <div className="row skill">
          <div className="three columns header-col">
            <h1><span>Skills</span></h1>
          </div>
          <div className="nine columns main-col">
            <p>
            </p>
            <div className="bars">
              <ul className="skills">
                <li><span className="bar-expand csharp" /><em>C#</em></li>
                <li><span className="bar-expand unity" /><em>Unity</em></li>
                <li><span className="bar-expand java" /><em>Java</em></li>
                <li><span className="bar-expand git" /><em>Git</em></li>
                <li><span className="bar-expand svn" /><em>Tortoise/SVN</em></li>
                <li><span className="bar-expand sql" /><em>SQL</em></li>
              </ul>
            </div>{/* end skill-bars */}
          </div> {/* main-col end */}
        </div> {/* End skills */}
      </section>
      </React.Fragment>
    );
  }
}