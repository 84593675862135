import React, { Component } from 'react';
export default class About extends Component {
  render() {
    return (
      <React.Fragment>
      {/*generated code*/}
      <section id="about">
        <div className="row">

                <h2>Contact Details</h2>
                <p className="address">
                  <span>Ben Vissotzky</span><br />
                  <span>2498 NW Schmidt Way<br />
                    Beaverton, OR 97006 US
                  </span><br />
                  <span>(360)600-1025</span><br />
                  <span>ben.vissotzky@gmail.com</span>
                </p>
        </div>
      </section>
      </React.Fragment>
    );
  }
}